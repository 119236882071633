import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      <h1>LA MARINE</h1>
	  <p>Welding & Fabrication</p>
	  <p>Electrical Systems</p>
	  <p>Woodwork - Referigeration</p>
	  <p>Engine Repair & Installation</p>
	  <p>Rigging & Crane Service</p>
	  <p>Plumbing - Painting</p>
	  	  <p>Fiberglass Repair</p>
	  	  	  <p><a href="mailto:lamarinesupply808@gmail.com">lamarinesupply808@gmail.com</a></p>
          <p>
        </p>
        </header>
    </div>
  );
}

export default App;
